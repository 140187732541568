
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';
export default {
  // 运营管理
  operationManage: [
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005190'),
      icon: '&#xe67b;',
      path: '/statistics',
      menuKey: 'supplier_statistics',
      hideCrumbs: true, // 隐藏面包屑
      guidanceModule: false, // 指引模块（默认开启）  true：开启指引模块    false  不开启指引模块
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006644'),
      icon: '&#xe668;',
      type: 'supplierOrder',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006645'),
          path: '/supplierOrderList',
          menuKey: 'supplier_supplierOrderList',
          guidanceModule: true
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1004413'),
          path: '/stockList',
          menuKey: 'supplier_stockList',
          guidanceModule: true,
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005427'),
              path: '/initiateStocking',
              menuKey: 'supplier_initiateStocking',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006646'),
              path: '/stockOrderDetails',
              menuKey: 'supplier_stockOrderDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006647'),
          path: '/shipmentRecord',
          menuKey: 'supplier_shipmentRecord',
          guidanceModule: true,
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006648'),
              path: '/shipmentRecordDetails',
              menuKey: 'supplier_shipmentRecordDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005415'),
              path: '/additionalGoods',
              menuKey: 'supplier_additionalGoods',
              menuHide: true // 菜单不展示
            }
          ]
        }
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006649'),
      icon: '&#xe678;',
      type: 'productCenter',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005493'),
          path: '/productCenter/productGoods',
          menuKey: 'supplier_productCenter_productGoods',
          guidanceModule: true,
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002513'),
              path: '/viewSku',
              menuKey: 'yms_viewSku',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006650'),
              path: '/productDetails',
              menuKey: 'supplier_productDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005540'),
              path: '/collectProducts',
              menuKey: 'yms_collectProducts',
              menuHide: true // 菜单不展示
            },
          ]
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006651'),
          path: '/productCenter/productBrand',
          menuKey: 'supplier_productCenter_productBrand',
          guidanceModule: true,
        }
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006652'),
      icon: '&#xe68c;',
      type: 'platformAuthorization',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006653'),
          path: '/platformAuthorization',
          menuKey: 'supplier_platformAuthorization',
          guidanceModule: true,
        }
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006654'),
      path: '/reconciliationManage',
      menuKey: 'supplier_reconciliationManage',
      icon: '&#xe665;',
      type: 'reconciliationManage',
      guidanceModule: true,
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003364'),
          path: '/reconciliationBillDetails',
          menuKey: 'supplier_reconciliationBillDetails',
          menuHide: true // 菜单不展示
        }
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000285'),
      path: '/messageCenter',
      menuKey: 'supplier_messageCenter',
      jurisdictionHide: true, // 不需要权限判断
      icon: '&#xe8be;',
      type: 'messageCenter',
      guidanceModule: true,
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006639'),
          path: '/messageMailboxes',
          menuKey: 'supplier_messageMailboxes',
          menuHide: true // 菜单不展示
        },
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006655'),
      icon: '&#xe6e3;',
      type: 'accountManage',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000591'),
          path: '/accountManage/basicInformation',
          menuKey: 'supplier_accountManage_basicInformation',
          guidanceModule: true,
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006640'),
          path: '/accountManage/management',
          menuKey: 'supplier_accountManage_management',
          guidanceModule: true,
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006656'),
          path: '/accountManage/collectionAccount',
          menuKey: 'supplier_accountManage_collectionAccount',
          guidanceModule: true,
        }
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006641'),
      icon: '&#xe664;',
      type: 'importExportTask',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006642'),
          path: '/importTask',
          menuKey: 'supplier_importTask'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006643'),
          path: '/exportTask',
          menuKey: 'supplier_exportTask'
        }
      ]
    }
  ],
  // 仓储管理
  warehouseManage: [
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002459'),
      path: '/wms/searchPackage',
      menuKey: 'supplier_wms_searchPackage',
      icon: '&#xe665;',
      type: 'searchPackage',
      guidanceModule: true,
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006657'),
          path: '/wms/searchPackageDetails',
          menuKey: 'supplier_wms_searchPackageDetails',
          menuHide: true // 菜单不展示
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005867'),
          path: '/addManualSupplierOrder',
          menuKey: 'supplier_addManualSupplierOrder',
          menuHide: true // 菜单不展示
        }
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006658'),
      path: '/wms/packingManage',
      menuKey: 'supplier_wms_packingManage',
      icon: '&#xe61b;',
      type: 'packingManage',
      guidanceModule: true,
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000534'),
          path: '/wms/exwarehouse',
          menuKey: 'supplier_wms_exwarehouse',
          menuHide: true, // 菜单不展示
          children: [
            {
              name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005722'),
              path: '/wms/scanEx',
              menuKey: 'supplier_wms_scanEx',
              menuHide: true // 菜单不展示
            }
          ]
        },
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002080'),
      path: '/wms/inventoryLevels',
      menuKey: 'supplier_wms_inventoryLevels',
      icon: '&#xe65f;',
      type: 'inventoryLevels',
      guidanceModule: true,
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006659'),
      icon: '&#xe63c;',
      type: 'orderPicking',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002424'),
          guidanceModule: true,
          path: '/wms/generateOrderList',
          menuKey: 'supplier_wms_generateOrderList'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006596'),
          path: '/wms/generateOrderDetails',
          menuKey: 'supplier_wms_generateOrderDetails',
          menuHide: true // 菜单不展示
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006597'),
          guidanceModule: true,
          path: '/wms/pickList',
          menuKey: 'supplier_wms_pickList'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002389'),
          path: '/wms/pickListDetails',
          menuKey: 'supplier_wms_pickListDetails',
          menuHide: true // 菜单不展示
        },
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006660'),
      icon: '&#xe630;',
      type: 'outboundPperation',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006661'),
          path: '/wms/sortOrderSetting',
          menuKey: 'supplier_wms_sortOrderSetting',
          guidanceModule: true,
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001823'),
          path: '/wms/sorting',
          menuKey: 'supplier_wms_sorting',
          guidanceModule: true,
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005849'),
          path: '/wms/packWorking',
          menuKey: 'supplier_wms_packWorking',
          guidanceModule: true,
        }
      ]
    },
    {
      name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006641'),
      icon: '&#xe664;',
      type: 'importExportTask',
      children: [
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006642'),
          path: '/importTask',
          menuKey: 'supplier_importTask'
        },
        {
          name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1006643'),
          path: '/exportTask',
          menuKey: 'supplier_exportTask'
        }
      ]
    }
  ],
};
