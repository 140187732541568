<template>
  <Modal ref="newEmployeeModal" v-model="newEmployeeModal" class-name="newEmployeeStyle" :title="newEmployeeTitle" width="1000"
    :transfer="false" :mask-closable="false" @on-visible-change="newEmployeeModalChange" :loading="modalLoading">
    <div class="container_box details_box_styles">
      <!--基本资料-->
      <div class="basic_information_box">
        <Card dis-hover>
          <template #title>
            <h2 class="title_box">{{ $t('key1004042') }}</h2>
          </template>
          <Form ref="newEmployeeParams" :model="newEmployeeParams" :label-width="90" :rules="ruleCustom"
            label-position="right" @submit.native.prevent>
            <Row type="flex" :gutter="gutterItem">
              <Col :span="oneItemCol">
                <Form-item :label="$t('key1001846')" prop="email">
                  <Input v-model.trim="newEmployeeParams.email" clearable></Input>
                </Form-item>
              </Col>
              <Col :span="oneItemCol">
                <Form-item :label="$t('key1001872')" prop="mobile">
                  <Input v-model.trim="newEmployeeParams.mobile" clearable></Input>
                </Form-item>
              </Col>
              <Col :span="oneItemCol">
                <Form-item :label="$t('key1001837')" prop="userName">
                  <Input v-model.trim="newEmployeeParams.userName" clearable></Input>
                </Form-item>
              </Col>
              <Col :span="oneItemCol" v-if="staffType !=='look'">
                <Form-item :label="$t('key1001900')" prop="password">
                  <div style="display: flex; align-items: center; justify-content: space-between;">
                    <Input style="width: 70%" v-model.trim="newEmployeeParams.password" clearable
                      :element-id="'newEmployeeParams_password'"></Input>
                    <Button style="width: 25%" @click="getStrongPassword('newEmployeeParams', 'password')">{{ $t('key1001901') }}</Button>
                  </div>
                </Form-item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
      <!--角色-->
      <div class="basic_information_box">
        <Card dis-hover>
          <template #title>
            <h2 class="title_box">{{ $t('key1005405') }}</h2>
          </template>
          <Checkbox-group class="roleList_box" v-model="newEmployeeParams.roleIdList">
            <div class="roleItem" v-for="(item, index) in changeRoleList" :key="index">
              <Checkbox :label="item.roleId" :title="item.title">
                <span class="page_role_item_title">{{ item.title }}</span>
              </Checkbox>
            </div>
          </Checkbox-group>
        </Card>
      </div>
    </div>
    <template #footer>
      <div style="text-align: center;">
        <Button @click="newEmployeeModal = false">{{ $t('key1000097') }}</Button>
        <Button type="primary" @click="newEmployeeBtn" v-if="(staffType === 'add' && getPermission('ymsSupplierUserInfo_insert'))
      || (staffType === 'edit' && getPermission('ymsSupplierUserInfo_update'))">{{ $t('key1000096') }}</Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';

export default {
  name: "staffModal",
  mixins: [Mixin],
  props: {
    staffType: {  // 弹窗的状态类型
      type: String,
      default: 'add'
    },
    roleList: { // 角色的数据
      type: Array,
      default: () => []
    }
  },
  data() {
    let v = this;
    return {
      modalLoading: false,
      oneItemCol: 12,
      newEmployeeTitle: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1004049'),
      newEmployeeModal: false,
      newEmployeeParams: {
        roleIdList: [],
        password: '',
        userName: '',
        email: '',
        mobile: ''
      },
      ruleCustom: {
        password: [
          {
            required: true,
            validator: v.validatePass(),
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            validator: v.validateMailboxFormat(),
            trigger: 'blur'
          }
        ],
        mobile: [
          {
            required: true,
            validator: v.validateMobile(),
            trigger: 'blur'
          }
        ],
        userName: [
          {
            required: true,
            validator: v.validateUserName(),
            trigger: 'blur'
          }
        ]
      },
      changeRoleList: []
    };
  },
  methods: {
    // 密码校验
    validatePass() {
      let v = this;
      return function (rule, value, callback) {
        if (v.newEmployeeParams.password === '' && v.uid === null) {
          callback(new Error(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000848')));
        } else {
          callback();
        }
      };
    },
    // 用户名校验，非空判断
    validateUserName() {
      let v = this;
      return function (rule, value, callback) {
        if (v.newEmployeeParams.userName === '') {
          callback(new Error(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002275')));
        } else {
          callback();
        }
      };
    },

    // 获取员工的基本信息
    getUserInfo(type, userId) {
      let v = this;
      if (type !== 'add') {
        this.userId = userId;
        v.axios.post(api.post_ymsSupplierUserInfo_id + userId, {}, {loading: true}).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            v.newEmployeeParams.roleIdList = data.roleIdList;
            v.newEmployeeParams.userName = data.userName;
            v.newEmployeeParams.email = data.email;
            v.newEmployeeParams.mobile = data.mobile;
            v.newEmployeeModal = true;
          }
        });
      }
    },

    // 新增、编辑员工的保存按钮
    newEmployeeBtn() {
      let v = this;
      let url = '';
      let text = '';
      let method = '';
      // 新增员工
      if (v.staffType === 'add') {
        text = aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000575');
        method = 'POST';
        url = api.post_ymsSupplierUserInfo;
      }
      // 编辑员工
      else if (v.staffType === 'edit') {
        text = aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000866');
        method = 'PUT';
        url = api.put_ymsSupplierUserInfo;
        v.newEmployeeParams.userId = v.userId;
        if (v.newEmployeeParams.password === '') {
          delete v.newEmployeeParams.password;
        }
        if (!v.newEmployeeParams.mobile) {
          v.newEmployeeParams.mobile = '';
        }
      }
      v.modalLoading = true;
      v.$refs['newEmployeeParams'].validate((valid) => {
        if (valid) {
          let query = {
            loading: true,
            loadingText: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000212'),
            method: method,
            url: url,
            data: Object.assign({}, v.newEmployeeParams)
          };
          v.axios(query).then(response => {
            v.modalLoading = false;
            if (response.data.code === 0) {
              v.newEmployeeModal = false;
              v.$Message.success(text);
              v.$emit('saveSuccess');
            }
          });
        }
      });
    },
    // 监听弹窗
    newEmployeeModalChange(value) {
      let v = this;
      if (value) {
        v.changeRoleList = v.roleList.slice(1, v.roleList.length);
      } else {
        v.newEmployeeParams.roleIdList = [];
        v.$refs['newEmployeeParams'].resetFields();
      }
    }
  },
  watch: {
    // 监听当前弹窗的状态类型
    staffType: {
      handler(value) {
        let v = this;
        if (value === 'add') {
          v.newEmployeeTitle = aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1004029');
        } else if (value === 'edit') {
          v.newEmployeeTitle = aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1004052');
        } else {
          v.newEmployeeTitle = aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1004051');
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.newEmployeeStyle {
  .roleList_box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .roleItem {
      padding: 4px 6px;
      border: 1px solid #d7dde4;
      border-radius: 4px;
      background-color: #f7f7f7;
      margin-left: 14px;
      margin-top: 11px;
      max-width: 210px;

      /deep/ .ivu-checkbox-group-item {
        display: flex;
        align-items: center;

        .page_role_item_title {
          display: block;
          margin-left: 6px;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: break-word;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
